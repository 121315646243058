import React, { Component } from 'react';
import { withErrorBoundary } from '@/utils/errors';
import { connect } from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';
import AsyncSelect from 'react-select/async';
import { publish } from '@/utils/publish';
import { Calendar } from 'react-calendar';
import styles from './CalendarTabs.module.scss';
import Router from 'next/router';
import { config } from '@/config';

import { random, setStateAsync } from '@/utils/helpers';
import { serializeEventObject } from '@/store/serializers/event';
import { searchUsers } from '@/store/actions/search.actions';
import { addToFavoriteUsers, fetchFavoriteUsers, removeFromFavoritesUsers } from '@/store/actions/currentUser.actions';
import TabsInfo from '../Helpers/SkeletonLoaders/pages/Contacts/TabsInfo';

const usersId = 'usersId';

class CalendarTabs extends Component {
  state = {
    calendar: null,
    calendarTabVisible: true,
    eventsTabVisible: true,
    usersTabVisible: true,
    favorite_user: '',
    active_favorite_user_id: '',
    excluded_ids: []
  };
  setStateAsync = setStateAsync.bind(this);

  async componentDidMount() {
    await this.props.fetchFavoriteUsers();
    await this.setStateAsync({
      calendar: Calendar,
      excluded_ids: [...this.props.favorite_users.map(el => el.id), this.props.currentUser.id],
      userKey: random()
    });
  }

  serializedParticularEvents() {
    return this.props.particular_events.map(e => serializeEventObject(e).eventObject);
  }

  get favoriteUsersTab() {
    const { favorite_user, active_favorite_user_id } = this.state;

    return (
      <div className={styles.favoriteContactsList}>
        <AsyncSelect
          key={this.state.userKey}
          instanceId={usersId}
          classNamePrefix="custom-select"
          className="custom-select"
          cacheOptions
          name="users"
          placeholder="Search by name..."
          value={favorite_user}
          onChange={this.selectUser}
          defaultOptions
          loadOptions={this.loadUsersOptions}
        />
        <div className={styles.list}>
          <div className={styles.userItem}>
            <input
              id="my-calendar"
              type="radio"
              name="my-calendar"
              className="is-checkradio is-medium"
              onChange={() => this.setActiveFavoriteUser('')}
              checked={active_favorite_user_id === ''}
            />
            <label htmlFor="my-calendar" />

            <p>My Calendar</p>
          </div>
          {this.props.favorite_users?.length > 0 &&
            this.props.favorite_users.map(user => {
              const name = `${user.profile.first_name} ${user.profile.last_name}`;
              return (
                <div key={user.id} className={styles.userItem}>
                  <input
                    className="is-checkradio is-medium"
                    id={`${user.id}favorite-user`}
                    type="radio"
                    name={`${user.id}favorite-user`}
                    onChange={() => this.setActiveFavoriteUser(user.id)}
                    checked={active_favorite_user_id === user.id}
                  />
                  <label htmlFor={`${user.id}favorite-user`} />

                  <p title={name}>{name}</p>
                  <i onClick={() => this.removeFromFavoritesList(user.id)} className="close fas fa-times" />
                </div>
              );
            })}
        </div>
      </div>
    );
  }

  selectUser = async favorite_user => {
    await this.props
      .addToFavoriteUsers(favorite_user.value)
      .then(this.props.fetchFavoriteUsers)
      .then(() => {
        this.setState({
          excluded_ids: [...this.props.favorite_users.map(el => el.id), this.props.currentUser.id],
          favorite_user: '',
          userKey: random()
        });
      })
      .catch(res => publish('error', res));
  };

  loadUsersOptions = async inputValue => {
    const allUsers = await this.props.searchUsers(inputValue, true, this.state.excluded_ids, {
      favorite_users: true
    });
    const data = this.usersOptions(allUsers.data);
    return new Promise(resolve => resolve(data));
  };

  usersOptions = users => {
    return users.map(user => ({
      user,
      value: user.id,
      label: user.first_name + ' ' + user.last_name
    }));
  };

  setActiveFavoriteUser = async active_favorite_user_id => {
    await this.props.fetchOnClick(active_favorite_user_id).then(() => this.setState({ active_favorite_user_id }));
    if (active_favorite_user_id) {
      if (
        this.props.favorite_users.find(el => el.downline && el.id === active_favorite_user_id) &&
        !config().ASBLEGACY
      ) {
        return this.props.checkMyEvents(true, active_favorite_user_id);
      }
      return this.props.checkMyEvents(false, active_favorite_user_id);
    }
    return this.props.checkMyEvents(true, this.props.currentUser.id);
  };

  removeFromFavoritesList = async id => {
    await this.props
      .removeFromFavoritesUsers(id)
      .then(this.props.fetchFavoriteUsers)
      .then(() =>
        this.setState({
          excluded_ids: [...this.props.favorite_users.map(el => el.id), this.props.currentUser.id],
          userKey: random()
        })
      )
      .then(() => {
        if (this.state.active_favorite_user_id === id) this.setActiveFavoriteUser('');
      })
      .catch(res => publish('error', res));
  };

  onChange = date => {
    date.activeStartDate ? this.props.changeDate(date?.activeStartDate) : this.props.changeDate(date);
  };

  render() {
    if (this.props.loading) {
      return <TabsInfo fluid opened loading />;
    }

    const Calendar = this.state.calendar || {};
    let startDate = moment(this.props.startDate).toDate();

    return (
      <div className={styles.tabsWrapper}>
        <div className={classNames(styles.tab, !this.state.calendarTabVisible && styles.closed)}>
          <div
            onClick={() => this.setState({ calendarTabVisible: !this.state.calendarTabVisible })}
            className={styles.header}
          >
            <i className="fas fa-chevron-down" />
            <p className="title">Calendar</p>
          </div>
          <div className={classNames(styles.tabContent, this.state.calendarTabVisible && styles.expanded)}>
            {this.state.calendar && (
              <Calendar
                formatShortWeekday={
                  (moment.updateLocale('en', { weekdaysMin: 'S_M_T_W_T_F_S'.split('_') }),
                  (_, date) => moment(date).format('dd'))
                }
                calendarType="gregory"
                onChange={this.onChange}
                value={startDate}
                activeStartDate={startDate}
                onActiveStartDateChange={this.onChange}
              />
            )}
          </div>
        </div>
        {this.props.currentUser?.c_1 && (
          <div className={styles.alineItemsCenter}>
            <button onClick={() => Router.push('/calendar-history')} type="button" className="button is-secondary">
              <p>Calendar log</p>
            </button>
          </div>
        )}
        <div className={classNames(styles.eventsTab, styles.tab, !this.state.eventsTabVisible && styles.closed)}>
          <div
            onClick={() => this.setState({ eventsTabVisible: !this.state.eventsTabVisible })}
            className={styles.header}
          >
            <i className="fas fa-chevron-down" />
            <p className="title">Today events</p>
          </div>
          <div className={classNames(styles.tabContent, this.state.eventsTabVisible && styles.expanded)}>
            {this.props.particular_events?.length > 0 ? (
              this.serializedParticularEvents().map(event => (
                <div key={event.id} className={styles.eventContent}>
                  <div className={styles.left}>
                    <div onClick={() => this.props.openTabEvent(event)} className={styles.visual}>
                      <div style={{ backgroundColor: event.color }} className={styles.calendarLabel} />
                    </div>
                    <div className={styles.content}>
                      <p className={styles.eventTitle} onClick={() => this.props.openTabEvent(event)}>
                        {event.title.length > 15 ? event.title.substr(0, 15) + '...' : event.title}
                      </p>
                      <p className={styles.description}>
                        {(!event.all_day && event.start_time && moment(event.start_time, 'HH:mm').format('LT | ')) ||
                          'All Day |'}
                        {event.rrule
                          ? 'Today'
                          : (event.start_datetime &&
                              moment
                                .parseZone(event.start_datetime)
                                .calendar()
                                .substring(0, moment.parseZone(event.start_datetime).calendar().indexOf(' at'))) ||
                            moment.parseZone(event.start_datetime).calendar()}
                      </p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className={styles.eventContent}>
                <p>No events today</p>
              </div>
            )}
          </div>
        </div>
        <div className={classNames(styles.usersTab, styles.tab, !this.state.usersTabVisible && styles.closed)}>
          <div
            onClick={() => this.setState({ usersTabVisible: !this.state.usersTabVisible })}
            className={styles.header}
          >
            <i className="fas fa-chevron-down"></i>
            <p className="title">Favorite users</p>
          </div>
          <div className={classNames(styles.tabContent, this.state.usersTabVisible && styles.expanded)}>
            {this.favoriteUsersTab}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  particular_events: state.event.particular_events,
  favorite_users: state.currentUser.favorite_users,
  currentUser: state.currentUser
});

export default connect(mapStateToProps, {
  searchUsers,
  addToFavoriteUsers,
  fetchFavoriteUsers,
  removeFromFavoritesUsers
})(withErrorBoundary(CalendarTabs));
