import { RRule } from 'rrule';

export const serializeEventObject = e => {
  const rrule = e.recurring_event_id ? null : e.rrule;
  let rruleStr = e.recurrence && RRule.fromString(e.recurrence.replace('RRULE:', ''));
  // const rrule = rruleObj && { ...rruleObj.options, ...rruleObj.origOptions };

  let rruleObj = rrule && {
    ...rrule,
    byweekday: rrule.byweekday.map(el => serializeByweekday(el)),
    ...rruleStr?.origOptions,
    freq: serializeFreq(rrule.freq),
    dtstart: rrule.dtstart,
    until: rrule.until
  };

  if (serializeFreq(rrule?.freq) !== RRule.WEEKLY && !(rruleObj?.byweekday && rruleObj.byweekday[0]?.n)) {
    delete rruleObj?.byweekday;
    delete rruleStr?.byweekday;
  }

  let nthWeek = false;
  if (serializeFreq(rrule?.freq) === RRule.MONTHLY && rruleObj?.byweekday && rruleObj.byweekday[0]?.n) {
    nthWeek = true;
  }

  return {
    id: e.id,
    type: e.type,
    title: e.title,
    start: e.fc_start,
    end: e.fc_end,
    rrule: rruleObj,
    duration: e.fc_duration,
    allDay: e.all_day,
    description: e.description,
    backgroundColor: e.color,
    event_detail: e.event_detail,
    event_guests: e.event_guests,

    eventObject: {
      ...e,
      rrule: rruleObj,
      nthWeek: nthWeek
    },
    editable: !rrule
  };
};

export const serializeFreq = freq => {
  return {
    DAILY: RRule.DAILY,
    WEEKLY: RRule.WEEKLY,
    MONTHLY: RRule.MONTHLY,
    YEARLY: RRule.YEARLY
  }[freq];
};

export const serializeByweekday = el => {
  return {
    MO: RRule.MO,
    TU: RRule.TU,
    WE: RRule.WE,
    TH: RRule.TH,
    FR: RRule.FR,
    SA: RRule.SA,
    SU: RRule.SU
  }[el.replace(/[0-9]/g, '')];
};

export const serializeEmailLog = array => {
  if (!Array.isArray(array)) return [];

  return array.reduce((acc, el) => {
    const { to_email, subject, message_id, tags, from_email, sent_at, scheduled_at, delivered_at, opened_at } = el;
    let emails = [];
    const emailLogTemplate = {
      email: to_email,
      subject: subject,
      messageId: message_id,
      tags: tags,
      from: from_email
    };

    if (!message_id) {
      emails.push({ ...emailLogTemplate, date: sent_at, event: 'not_delivered' });
    } else {
      if (scheduled_at) {
        emails.push({ ...emailLogTemplate, date: scheduled_at, event: 'scheduled' });
      }
      if (opened_at) {
        emails.push({ ...emailLogTemplate, date: opened_at, event: 'opened' });
      }
      if (delivered_at) {
        emails.push({ ...emailLogTemplate, date: delivered_at, event: 'delivered' });
      }
      if (sent_at) {
        emails.push({ ...emailLogTemplate, date: sent_at, event: 'sent' });
      }
    }

    return [...acc, ...emails];
  }, []);
};

export const emailLogEvent = el => {
  const { message_id, sent_at, scheduled_at, delivered_at, opened_at } = el;

  if (!message_id) {
    return 'not_delivered';
  } else {
    if (scheduled_at) {
      return 'scheduled';
    }
    if (opened_at) {
      return 'opened';
    }
    if (delivered_at) {
      return 'delivered';
    }
    if (sent_at) {
      return 'sent';
    }
  }
};
