import React from 'react';
import { withErrorBoundary } from '@/utils/errors';

const Shimmer = () => {
  return (
    <div className="shimmer-wrapper">
      <div className="shimmer"></div>
    </div>
  );
};

export default withErrorBoundary(Shimmer);
