import React, { Component } from 'react';
import { withErrorBoundary } from '@/utils/errors';
import { connect } from 'react-redux';
import dynamic from 'next/dynamic';
import moment from 'moment';
import classNames from 'classnames';
import { config } from '@/config';

import styles from './Calendar.module.scss';

import { EVENT_APPOINTMENT, EVENT_FOLLOW_UP, EVENT_TASK, EVENT_OTHER, EVENT_UNAVAILABLE } from '@/store/models/event';
import EventModal from '@/components/Calendar/EventModal';
import Modal from '@/components/Base/Modal';
import CalendarTabs from '@/components/Calendar/CalendarTabs';

import { setStateAsync, debounce } from '@/utils/helpers';
import { publish } from '@/utils/publish';
import { abortSignal } from '@/utils/request';
import Scroll from '@/components/Helpers/Scroll';
import {
  fetchEvents,
  updateEvent,
  fetchParticularEvents,
  fetchContactEvents,
  saveCalendarDateRange
} from '@/store/actions/event.actions';
import { serializeEventObject } from '@/store/serializers/event';

import CalendarLoader from '@/components/Helpers/SkeletonLoaders/pages/Calendar/CalendarLoader';

const CalendarWrapper = dynamic(() => import('@/components/CalendarWrapper'), {
  ssr: false
});

const SEARCH_DELAY_TIME = 500;
const LIGHT_BACKGROUND_COLORS = ['#ffff00', '#00ff00'];

const calendarRef = React.createRef();
const screenshotRef = React.createRef();

class Calendar extends Component {
  setStateAsync = setStateAsync.bind(this);

  state = {
    // eventsLoading: true,
    tabsLoading: true,
    createEventModalIsVisible: false,
    editEventModalIsVisible: false,
    editEvent: {},
    startDate: moment().format('YYYY-MM-DD'),
    type: '',
    myPage: true,
    downlineId: this.props.currentUser.id,
    owner: {},
    searchValue: ''
  };

  defaultOptions = {
    displayEventEnd: true,
    timeZone: false,
    slotMinTime: '07:00:00',
    slotMaxTime: '31:00:00',
    contentHeight: 'auto',
    initialView: this.props.isMini ? 'dayGridWeek' : this.props.listView ? 'customListView' : 'dayGridMonth',
    editable: false,
    droppable: true,
    selectable: true,
    dragScroll: true,
    // selectHelper: true,
    nowIndicator: true,
    weekNumberFormat: { week: 'numeric' },
    eventDisplay: 'block',
    views: {
      customListView: {
        type: 'list',
        ...this.customListViewDuration,
        ...this.customListViewVsisbleRange
      },
      dayGridWeek: {
        type: 'dayGridWeek',
        visibleRange: {
          start: moment().format('YYYY-MM-DD'),
          end: moment().add(7, 'days').format('YYYY-MM-DD')
        },
        duration: { days: 7 }
      },
      timeGridDay: { buttonText: 'Day' },
      timeGridWeek: { buttonText: 'Week' },
      dayGridMonth: { buttonText: 'Month' },
      listDay: { buttonText: 'Agenda - Day' },
      listWeek: { buttonText: 'Agenda - Week' }
    },
    eventContent: info => {
      let title = info.event.title;
      if (!this.state.myPage && !config().ASBLEGACY) {
        title = 'Calendar Event';
      }
      if (this.props.isMini) {
        return (
          <div className="fc-event-main-frame">
            <div className="fc-event-title-container">
              <div className="fc-event-title fc-sticky">
                {info.timeText} {title}
              </div>
            </div>
          </div>
        );
      } else if (this.props.listView) {
        const offset = moment(info.event.start).utcOffset();
        return (
          <>
            <div class="fc-title-block">
              <div style={{ backgroundColor: info.event.backgroundColor }} class="fc-color"></div>
              <span class="fc-title">{title}</span>
            </div>
            <span class="fc-time">
              {(!info.event.allDay &&
                info.event.start &&
                moment.parseZone(info.event.start, 'HH:mm').subtract(offset, 'minutes').format('LT | ')) ||
                'All Day | '}
              {(info.event.start &&
                moment
                  .parseZone(info.event.start)
                  .subtract(offset, 'minutes')
                  .calendar()
                  .substring(
                    0,
                    moment.parseZone(info.event.start).subtract(offset, 'minutes').calendar().indexOf(' at')
                  )) ||
                moment.parseZone(info.event.start).subtract(offset, 'minutes').calendar()}
            </span>
          </>
        );
      } else if (['listDay', 'listWeek'].includes(info.view.type)) {
        return this.listDayWeekView(info);
      } else {
        return (
          <div className="fc-event-main-frame">
            <div className="fc-event-time">{info.timeText}</div>
            <div className="fc-event-title-container">
              <div className="fc-event-title fc-sticky">{title}</div>
            </div>
          </div>
        );
      }
    },
    eventTimeFormat: {
      hour: this.props.isMini ? 'numeric' : '2-digit',
      minute: '2-digit',
      meridiem: this.props.isMini ? 'narrow' : 'lowercase',
      hour12: true
    },
    headerToolbar: {
      left: this.props.listView ? '' : 'today,prev,next title',
      right: this.props.isMini || this.props.listView ? '' : 'timeGridDay,timeGridWeek,dayGridMonth,listDay,listWeek'
    },
    buttonText: {
      today: 'Today'
    },
    dateClick: info => {
      this.setState({ startDate: info.dateStr });
      this.openCreateEventModal();
    },
    eventDrop: info => {
      this.onEventAction(info);
    },
    eventResize: info => {
      this.onEventAction(info);
    },
    datesSet: async info => {
      const viewDomElement = document.getElementsByClassName('fc-view-harness').item(0);
      if (!this.props.listView && viewDomElement) viewDomElement.style.display = 'none';

      this.props.saveCalendarDateRange({ start_date: info.startStr, end_date: info.endStr });

      if (!['listDay', 'listWeek'].includes(info.view.type) && this.state.searchValue.length >= 3) {
        await this.setStateAsync({ searchValue: '' });
      }

      this.fetchEvents({
        start: moment(info.startStr).format('MM/DD/YYYY'),
        end: moment(info.endStr).format('MM/DD/YYYY'),
        q: this.state.searchValue.length >= 3 ? this.state.searchValue : null
      });
    },
    eventClick: info => {
      const srcElement = info.jsEvent.srcElement;
      if (srcElement?.id && (srcElement.id == 'ePhone' || srcElement.id == 'eLocation')) {
        return;
      }
      this.setState(
        {
          editEvent: {
            eventObject: info.event.extendedProps.eventObject
          }
        },
        () => (this.props.listView ? this.props.openTabEvent(this.state.editEvent) : this.openEditEventModal())
      );
    }
  };

  componentDidUpdate(_prevProps, _prevState) {
    const viewDomElement = document.getElementsByClassName('fc-view-harness').item(0);
    if (viewDomElement && !this.props.listView) {
      // && !this.state.eventsLoading
      viewDomElement.style.display = 'block';
    }
  }

  fetchEvents = async ({ start, end, q = null }) => {
    abortSignal(['/events?']);
    // await this.setStateAsync({ eventsLoading: true });
    this.props
      .fetchEvents({
        downline_user_id: this.state.downlineId || this.props.currentUser.id,
        start_date: start || this.props.activeCalendarRange?.start_date,
        end_date: end || this.props.activeCalendarRange?.end_date,
        q: q
      })
      .then(() => this.props.calendarPage && this.fetchParticularEvents())
      .catch(err => publish('error', err));
    // .then(() => this.setState({ eventsLoading: false }))
  };

  get customListViewDuration() {
    if (this.props.listViewPastDue || this.props.pastdue) return {};
    if (this.props.listViewToday) return { duration: { days: 1 } };
    return { duration: { months: 6 } };
  }

  get customListViewVsisbleRange() {
    if (this.props.listViewPastDue || this.props.pastdue)
      return {
        visibleRange: {
          start: moment().subtract(10, 'years').format('YYYY-MM-DD HH:mm'),
          end: moment().format('YYYY-MM-DD HH:mm')
        }
      };
    return {
      visibleRange: {
        start: moment().format('YYYY-MM-DD HH:mm'),
        end: moment().add(1, 'year').format('YYYY-MM-DD HH:mm')
      }
    };
  }

  onEventAction = async info => {
    if (this.state.myPage) {
      let end_date = info.event.end;
      if (end_date) {
        if (info.event.allDay) {
          let newEndDate = new Date(end_date);
          end_date = newEndDate.setDate(newEndDate.getDate() - 1);
        }
        end_date = moment.utc(end_date).format('MM/DD/YYYY');
      }
      const data = {
        id: info.event.id,
        all_day: info.event.allDay,
        start_date: info.event.start && moment.utc(info.event.start).format('MM/DD/YYYY'),
        end_date: end_date,
        start_time: info.event.start && moment.utc(info.event.start).format('HH:mm'),
        end_time: info.event.end && moment.utc(info.event.end).format('HH:mm')
      };

      await this.props
        .updateEvent(data)
        .then(() => {
          // this.props.fetchEvents({
          //   downline_user_id: this.state.downlineId || this.props.currentUser.id,
          //   start_date: this.props.activeCalendarRange?.start_date,
          //   end_date: this.props.activeCalendarRange?.end_date
          // });
        })
        .then(this.props.onSuccessAction)
        .then(() => this.props.calendarPage && this.fetchParticularEvents())
        .catch(err => publish('error', err));
    }
  };

  changeDate = date => {
    this.setState({ startDate: date });
    const calendarApi = calendarRef.current?.getApi();
    calendarApi.gotoDate(date);
  };

  openTabEvent = async event => {
    await this.setStateAsync({ editEvent: { eventObject: event } });
    this.setState({ editEventModalIsVisible: true });
  };

  openCreateEventModal = () => this.setState({ createEventModalIsVisible: true });

  closeCreateEventModal = async () => {
    this.setState({ createEventModalIsVisible: false });
    this.props.calendarPage && this.fetchParticularEvents();
    this.props.contact && this.props.fetchContactEvents(this.props.contact.id);
  };

  openEditEventModal = () => this.setState({ editEventModalIsVisible: true });

  closeEditEventModal = async () => {
    this.setState({ editEventModalIsVisible: false });
    this.props.calendarPage && this.fetchParticularEvents();
    this.props.contact && this.props.fetchContactEvents(this.props.contact.id);
  };

  fetchParticularEvents = async () => {
    await this.props
      .fetchParticularEvents({
        start_date: moment().format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
        today_events: true
      })
      .then(() => this.setState({ tabsLoading: false }))
      .catch(res => publish('error', res));
  };

  openCreateEventModalWithType = async type => {
    await this.setStateAsync({ type });
    await this.openCreateEventModal();
    await this.setStateAsync({ type: null });
  };

  fetchOnClick = async downline_user_id => {
    abortSignal(['/events?']);
    await this.props
      .fetchEvents({
        downline_user_id,
        start_date: this.props.activeCalendarRange?.start_date,
        end_date: this.props.activeCalendarRange?.end_date
      })
      .catch(res => publish('error', res));
  };

  checkMyEvents = (myPage, downlineId) => {
    const owner = this.props.currentUser.favorite_users.find(el => el.id === downlineId) || this.props.currentUser;
    this.setState({
      myPage,
      createEventModalIsVisible: false,
      editEventModalIsVisible: false,
      downlineId,
      owner: { value: owner.id, label: `${owner.profile.first_name} ${owner.profile.last_name}` }
    });
  };

  listDayWeekView = info => {
    let lv_contacts = [];
    let lv_contact = null;

    let lv_title = '';
    let lv_description = '';
    let lv_location = '';
    let lv_phone = '';
    let lv_right = '';

    lv_contacts = info.event._def.extendedProps.event_guests.filter(val => {
      return val.guestable_type == 'Contact';
    });

    if (lv_contacts.length >= 1) {
      lv_contact = lv_contacts[0];
    }

    if (
      info?.event?._def?.extendedProps?.eventObject?.location &&
      info?.event?._def?.extendedProps?.eventObject?.location != null &&
      info?.event?._def?.extendedProps?.eventObject?.location != ''
    ) {
      lv_location = info?.event?._def?.extendedProps?.eventObject?.location;
    } else if (lv_contact && lv_contact.guest) {
      lv_location =
        (lv_contact.guest?.address ? lv_contact.guest?.address : '') +
        ' ' +
        (lv_contact.guest?.city ? lv_contact.guest?.city : '') +
        ' ' +
        (lv_contact.guest?.state ? lv_contact.guest?.state : '') +
        ' ' +
        (lv_contact.guest?.zip_code ? lv_contact.guest?.zip_code : '');
    }

    if (
      info?.event?._def?.extendedProps?.eventObject?.phone &&
      info?.event?._def?.extendedProps?.eventObject?.phone != null &&
      info?.event?._def?.extendedProps?.eventObject?.phone != ''
    ) {
      lv_phone = info?.event?._def?.extendedProps?.eventObject?.phone;
    } else if (lv_contact && lv_contact.guest) {
      lv_phone = lv_contact.guest?.phone ? lv_contact.guest?.phone : '';
    }

    if (info.event.title) {
      lv_title = info.event.title;
    }

    if (info.event._def.extendedProps.description) {
      lv_description = info.event._def?.extendedProps?.description;
    }

    if (lv_location && lv_phone && lv_location != '' && lv_phone != '') {
      lv_right = (
        <>
          <a
            id="eLocation"
            href={`https://maps.google.com/?q=${lv_location}`}
            target="_blank"
            rel="noreferrer"
            onClick="e => { e.stopPropagation() }"
          >
            {lv_location}
          </a>
          <br />
          <a id="ePhone" href="tel:${lv_phone}" target="_blank" rel="noreferrer" onClick="e => { e.stopPropagation() }">
            {lv_phone}
          </a>
        </>
      );
    } else if (lv_location && lv_location != '') {
      lv_right = (
        <a
          id="eLocation"
          href="https://maps.google.com/?q=${lv_location}"
          target="_blank"
          rel="noreferrer"
          onClick="e => { e.stopPropagation() }"
        >
          {lv_location}
        </a>
      );
    } else if (lv_phone && lv_phone != '') {
      lv_right = (
        <a id="ePhone" href={`tel:${lv_phone}`} target="_blank" rel="noreferrer" onClick="e => { e.stopPropagation() }">
          {lv_phone}
        </a>
      );
    }

    return (
      <>
        <div class="columns">
          <div class="column">{lv_title}</div>
          <div class="column">{lv_right}</div>
        </div>
        <div class="columns">
          <div class="column">{lv_description}</div>
        </div>
      </>
    );
  };

  printCalendar = async () => window.print();

  get body() {
    return (
      <div className={classNames('columns', styles.columns)}>
        {!this.props.listView && !this.props.isMini && (
          <div id="cal-side-view" className={classNames('column is-3', styles.column, styles.is3)}>
            <CalendarTabs
              checkMyEvents={this.checkMyEvents}
              fetchOnClick={this.fetchOnClick}
              changeDate={this.changeDate}
              startDate={this.state.startDate}
              openTabEvent={this.openTabEvent}
              loading={this.state.tabsLoading}
            />
          </div>
        )}

        <div
          className={classNames(
            !this.props.listView && 'column',
            styles.column,
            styles.withCalendar,
            this.props.contact && styles.marginTopColumn
          )}
        >
          {['listDay', 'listWeek', 'listYear'].includes(this.currentView) && (
            <div className="search-wrapper" style={{ margin: '10px 0 20px', width: '30%' }}>
              <div className="control has-icons-left">
                <input
                  name="search"
                  className={classNames('input is-large', {
                    'is-danger': this.props.search && this.props.search.length < 3
                  })}
                  type="search"
                  placeholder="Quick search"
                  autoComplete="off"
                  value={this.state.searchValue}
                  onChange={async e => {
                    await this.setStateAsync({ searchValue: e.target.value });
                    this.onSearch(e);
                  }}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-search" />
                </span>
              </div>
              {this.state.searchValue && this.state.searchValue.length < 3 && (
                <p className="help is-danger error">Must contain at least 3 characters</p>
              )}
            </div>
          )}

          <div className={this.props.isMini ? '' : styles.screenshotWrapper} ref={screenshotRef}>
            <CalendarWrapper {...this.defaultOptions} refProp={calendarRef} events={this.props.events} />
            {!this.props.isMini && !this.props.listView && (
              <div
                id="cal-print-btn"
                className={styles.screenshotButtonWrapper}
                onClick={this.printCalendar}
                role="button"
              >
                <i className="fas fa-print" />
              </div>
            )}
            {!this.props.listView && (
              <div id="cal-add-btn" className={styles.plusButton}>
                <i className="fas fa-plus"></i>
                <i className="fas fa-chevron-down"></i>
                <div className={classNames('dropdown-content', styles.dropdownContent)}>
                  <p onClick={() => this.openCreateEventModalWithType(EVENT_APPOINTMENT)} role="button">
                    Appointment
                  </p>
                  {!this.props.currentUser.c_1 && (
                    <p onClick={() => this.openCreateEventModalWithType(EVENT_FOLLOW_UP)} role="button">
                      Follow Up
                    </p>
                  )}
                  {!this.props.currentUser.c_1 && !this.props.contact && (
                    <p onClick={() => this.openCreateEventModalWithType(EVENT_TASK)} role="button">
                      Task
                    </p>
                  )}
                  {!this.props.currentUser.c_1 && (
                    <p onClick={() => this.openCreateEventModalWithType(EVENT_OTHER)} role="button">
                      Other
                    </p>
                  )}
                  {!this.props.currentUser.c_1 && !this.props.contact && (
                    <p onClick={() => this.openCreateEventModalWithType(EVENT_UNAVAILABLE)} role="button">
                      Unavailable
                    </p>
                  )}
                </div>
              </div>
            )}
          </div>

          {/* {this.state.eventsLoading && (
            <CalendarLoader
              loading
              withHeader={false}
              bodyWidth={this.props.isMini ? 5 : 6}
              bodyHeight={this.props.isMini ? 1 : 6}
            />
          )} */}
        </div>
      </div>
    );
  }

  get currentView() {
    const calendarApi = calendarRef.current?.getApi();
    return calendarApi?.view?.type;
  }

  onSearch = debounce(async () => {
    let { searchValue } = this.state;

    if (searchValue.length < 3 && searchValue.length > 0) {
      return;
    }

    this.fetchEvents({
      start: this.props.activeCalendarRange?.start_date,
      end: this.props.activeCalendarRange?.end_date,
      q: this.state.searchValue.length >= 3 ? this.state.searchValue : null
    });
  }, SEARCH_DELAY_TIME);

  render() {
    return (
      <>
        <div
          className={classNames(
            'columns-wrapper',
            this.props.listView && 'listViewWrapper DashboardsCardsResponsiveGridLayoutCancelSelector',
            this.props.listViewPastDue && 'listViewPastDueWrapper',
            this.props.listView && styles.listViewWrapper,
            !this.props.listView && styles.columnsWrapper,
            styles.calendar,
            styles.calendarWrapper
          )}
        >
          {this.props.listView ? <Scroll>{this.body}</Scroll> : this.body}
        </div>
        {this.state.createEventModalIsVisible && this.state.myPage && (
          <Modal classNames={'event-modal'} onClose={this.closeCreateEventModal}>
            <EventModal
              type={this.state.type}
              downlineId={this.state.downlineId}
              owner={this.state.owner}
              startDate={this.state.startDate}
              onClose={this.closeCreateEventModal}
              contactData={this.props.contact}
              onSuccessAction={this.props.onSuccessAction}
            />
          </Modal>
        )}
        {this.state.editEventModalIsVisible && (this.state.myPage || config().ASBLEGACY) && (
          <Modal classNames={'event-modal'} onClose={this.closeEditEventModal}>
            <EventModal
              downlineId={this.state.downlineId}
              onClose={this.closeEditEventModal}
              event={{ ...this.state.editEvent }}
              onSuccessAction={this.props.onSuccessAction}
              disabledFields={!this.state.myPage && config().ASBLEGACY}
            />
          </Modal>
        )}
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    events: props.listView
      ? props.dashboardEvents?.filter(e => e.completed === false)?.map(e => serializeEventObject(e)) || []
      : state.event.events?.filter(e => e.completed === false)?.map(e => serializeEventObject(e)) || [],
    currentUser: state.currentUser,
    activeCalendarRange: state.event.activeCalendarRange
  };
};

export default connect(mapStateToProps, {
  fetchEvents,
  updateEvent,
  fetchParticularEvents,
  fetchContactEvents,
  saveCalendarDateRange
})(withErrorBoundary(Calendar));
