import React from 'react';
import { withErrorBoundary } from '@/utils/errors';

import { random } from '@/utils/helpers';
import SkeletonElement from '@/components/Helpers/SkeletonLoaders/SkeletonElement';
import SkeletonWrapper from '@/components/Helpers/SkeletonLoaders/SkeletonWrapper';

import styles from './CalendarLoader.module.scss';

const CalendarLoader = ({ loading, bodyHeight = 7, bodyWidth = 7, withHeader = true }) =>
  loading ? (
    <div className={styles.calendar}>
      <SkeletonWrapper noMargin>
        {withHeader && (
          <div className={styles.calendarHead}>
            <div className={styles.calendarItem}>
              <SkeletonElement type="paragraph" />
              <SkeletonElement type="paragraph margin-left" />
              <SkeletonElement type="paragraph margin-left" />
            </div>
            <div className={styles.calendarItem}>
              <SkeletonElement type="paragraph" />
              <SkeletonElement type="paragraph big margin-left" />
            </div>
          </div>
        )}
        <div className={styles.calendarWrapper}>
          {withHeader && (
            <div className={styles.head}>
              <SkeletonElement type="paragraph" />
              <SkeletonElement type="paragraph" />
              <SkeletonElement type="paragraph" />
              <SkeletonElement type="paragraph" />
              <SkeletonElement type="paragraph" />
              <SkeletonElement type="paragraph" />
              <SkeletonElement type="paragraph" />
            </div>
          )}
          {[...Array(bodyHeight).keys()].map(() => {
            return (
              <div key={random()} className={styles.body}>
                {[...Array(bodyWidth).keys()].map((el, i) => {
                  return (
                    <div key={i} className={styles.box}>
                      <SkeletonElement type="paragraph margin-bottom" />
                      <SkeletonElement type="paragraph margin-left" />
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </SkeletonWrapper>
    </div>
  ) : null;

export default withErrorBoundary(CalendarLoader);
