import React from 'react';
import { withErrorBoundary } from '@/utils/errors';
import classNames from 'classnames';

import styles from './Scroll.module.scss';

const Scroll = props => {
  return <div className={classNames(styles.scrollbar, styles.style1)}>{props.children}</div>;
};

export default withErrorBoundary(Scroll);
