import React from 'react';
import { withErrorBoundary } from '@/utils/errors';

import SkeletonElement from '../SkeletonElement';
import SkeletonWrapper from '../SkeletonWrapper';

const TwoButtons = ({ loading }) =>
  loading ? (
    <SkeletonWrapper>
      <div style={{ display: 'flex' }}>
        <SkeletonElement type="paragraph" />
        <SkeletonElement type="paragraph margin-left" />
      </div>
    </SkeletonWrapper>
  ) : null;

export default withErrorBoundary(TwoButtons);
