import React from 'react';
import { withErrorBoundary } from '@/utils/errors';
import classNames from 'classnames';

import SkeletonElement from '@/components/Helpers/SkeletonLoaders/SkeletonElement';
import SkeletonWrapper from '@/components/Helpers/SkeletonLoaders/SkeletonWrapper';
import TwoButtons from '@/components/Helpers/SkeletonLoaders/elements/TwoButtons';

import styles from './TabsInfo.module.scss';

const bodyLength = 6;

const TabsInfo = ({ loading, opened, fluid }) =>
  loading ? (
    <div className={classNames(styles.tabsInfo, fluid && styles.fluid, !opened && styles.closed)}>
      <SkeletonWrapper noMargin>
        <div className={styles.toggler}>
          <SkeletonElement type="thumbnail small" />
        </div>
        <div className={styles.tab}>
          <SkeletonElement type="paragraph big margin-bottom" />
          <SkeletonElement type="paragraph margin-bottom" />
          <TwoButtons loading />
        </div>
        {[...Array(bodyLength).keys()].map((_, i) => {
          return (
            <div key={i} className={styles.tab}>
              <SkeletonElement type="paragraph big margin-bottom" />
              <SkeletonElement type="paragraph margin-bottom" />
              <TwoButtons loading />
            </div>
          );
        })}
      </SkeletonWrapper>
    </div>
  ) : null;

export default withErrorBoundary(TabsInfo);
